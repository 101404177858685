import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    meta: {},
    component: () => import(/* webpackChunkName: "home" */ '../views/Home.vue')
  },
  {
    path: '/about',
    name: 'About',
    meta: {
      title: 'Sobre Nós',
      image: 'breadcrumb-bg_aboutus.png'
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutUs.vue')
  },
  {
    path: '/history',
    name: 'History',
    meta: {
      title: 'Nossa História',
      image: 'breadcrumb-bg_aboutus.png'
    },
    component: () => import(/* webpackChunkName: "history" */ '../views/History.vue')
  },
  {
    path: '/structure',
    name: 'Structure',
    meta: {
      title: 'Estrutura',
      image: 'breadcrumb-bg_aboutus.png'
    },
    component: () => import(/* webpackChunkName: "structure" */ '../views/Structure.vue')
  },
  {
    path: '/reports',
    name: 'Reports',
    meta: {
      title: 'Relatórios',
      image: 'breadcrumb-bg_aboutus.png'
    },
    component: () => import(/* webpackChunkName: "reports" */ '../views/Reports.vue')
  },
  {
    path: '/contact',
    name: 'Contact',
    meta: {
      title: 'Contactos',
      image: 'breadcrumb-bg_aboutus.png'
    },
    component: () => import(/* webpackChunkName: "contact" */ '../views/Contact.vue')
  },
  {
    path: '/particulares',
    name: 'Particulares',
    meta: {
      title: 'Particulares',
      image: 'breadcrumb-bg_aboutus.png'
    },
    component: () => import(/* webpackChunkName: "particulares" */ '../views/Particulares.vue')
  },
  {
    path: '/empresas',
    name: 'Empresas',
    meta: {
      title: 'Empresas',
      image: 'breadcrumb-bg_aboutus.png'
    },
    component: () => import(/* webpackChunkName: "empresas" */ '../views/Empresas.vue')
  }, 
  {
    path: '/simulador',
    name: 'Simulador',
    meta: {
      title: 'Simulador',
      image: 'breadcrumb-bg_aboutus.png'
    },
    component: () => import(/* webpackChunkName: "simulador" */ '../views/Simulador.vue')
  },
  {
    path: '/creditopessoalrapido',
    name: 'CreditoPessoalRapido',
    meta: {
      title: 'Crédito Pessoal Rápido',
      image: 'breadcrumb-bg_particulares.png'
    },
    component: () => import(/* webpackChunkName: "creditopessoalrapido" */ '../views/CreditoPessoalRapido.vue')
  },
  {
    path: '/creditoautomovel',
    name: 'CreditoAutomovel',
    meta: {
      title: 'Crédito Automóvel',
      image: 'breadcrumb-bg_particulares.png'
    },
    component: () => import(/* webpackChunkName: "creditoautomovel" */ '../views/CreditoAutomovel.vue')
  },
  {
    path: '/creditolarrecheio',
    name: 'CreditoLarRecheio',
    meta: {
      title: 'Crédito Lar Recheio',
      image: 'breadcrumb-bg_particulares.png'
    },
    component: () => import(/* webpackChunkName: "creditolarrecheio" */ '../views/CreditoLarRecheio.vue')
  },
  {
    path: '/creditohabitacao',
    name: 'CreditoHabitacao',
    meta: {
      title: 'Crédito Habitação',
      image: 'breadcrumb-bg_particulares.png'
    },
    component: () => import(/* webpackChunkName: "creditohabitacao" */ '../views/CreditoHabitacao.vue')
  },
  {
    path: '/creditoconstrucao',
    name: 'CreditoConstrucao',
    meta: {
      title: 'Crédito Construção',
      image: 'breadcrumb-bg_particulares.png'
    },
    component: () => import(/* webpackChunkName: "creditoconstrucao" */ '../views/CreditoConstrucao.vue')
  },
  {
    path: '/creditopme',
    name: 'CreditoPME',
    meta: {
      title: 'Crédito PME-Rural Comércio',
      image: 'breadcrumb-bg_empresas.png'
    },
    component: () => import(/* webpackChunkName: "creditopme" */ '../views/CreditoPME.vue')
  },
  {
    path: '/creditorural',
    name: 'CreditoRural',
    meta: {
      title: 'Crédito Rural Agro-Pecuário',
      image: 'breadcrumb-bg_empresas.png'
    },
    component: () => import(/* webpackChunkName: "creditorural" */ '../views/CreditoRural.vue')
  },
  {
    path: '/descontolivranca',
    name: 'DescontoLivranca',
    meta: {
      title: 'Desconto de Livranças',
      image: 'breadcrumb-bg_empresas.png'
    },
    component: () => import(/* webpackChunkName: "descontolivranca" */ '../views/DescontoLivranca.vue')
  },
  {
    path: '/garantiasbancarias',
    name: 'GarantiasBancarias',
    meta: {
      title: 'Garantias Bancarias',
      image: 'breadcrumb-bg_empresas.png'
    },
    component: () => import(/* webpackChunkName: "garantiasbancarias" */ '../views/GarantiasBancarias.vue')
  },
  {
    path: '/termosecondicoes',
    name: 'TermoseCondicoes',
    meta: {
      title: 'Termos e Condicoes',
      image: 'breadcrumb-bg_aboutus.png'
    },
    component: () => import(/* webpackChunkName: "garantiasbancarias" */ '../views/TermsConditions.vue')
  }
]

const router = new VueRouter({
  mode: 'hash',
  scrollBehavior(to, from, savedPosition) {
    if (to.hash) {
      return { selector: to.hash }
    } else {
      return { x: 0, y: 0 }
    }
  },
  routes
})

export default router
