<template>
  <!-- Footer Section Begin -->
  <footer class="footer set-bg" data-setbg="img/footer-bg.jpg">
    <div class="container">
      <div class="footer__contact">
        <div class="row">
          <div class="col-lg-3 col-md-4">
            <div class="footer__contact__title">
              <h2>INSTITUCIONAL</h2>
            </div>
          </div>
          <div class="col-lg-6 col-md-4">
            <div class="footer__contact__title">
              <h2 style="text-align:center">PRODUTOS</h2>
            </div>
          </div>
          <div class="col-lg-3 col-md-4">
            <div class="footer__contact__title">
              <h2 style="text-align:right">CONTACTOS</h2>
            </div>
          </div>
        </div>
      </div>

      <div class="row">

        <div class="col-lg-3 col-md-2">
          <div class="footer__widget">
            <h5>Sobre Nós</h5>
            <ul>
              <li><i class="fa fa-chevron-circle-right" style="color:white;"></i> <router-link to="/About">A Nossa História</router-link>
                </li>
              
              <li><router-link to="/About#section2"><i class="fa fa-chevron-circle-right" style="color:white;"></i> Missão e
                Visão</router-link></li>
              <li><router-link to="/structure"><i class="fa fa-chevron-circle-right" style="color:white;"></i> Estrutura
                Governança</router-link></li>
              <li><router-link to="/reports" ><i class="fa fa-chevron-circle-right" style="color:white;"></i>
                Relatórios</router-link></li>
            </ul>
          </div>
        </div>
        <div class="col-lg-3 col-md-3">
          <div class="footer__widget">
            <h5 style="text-align:left">Crédito Consumo</h5>
            <ul>
              <li><router-link to="/creditopessoalrapido" ><i class="fa fa-chevron-circle-right" style="color:white;"></i>
                Crédito Pessoal Rápido</router-link></li>
              <li><router-link to="/creditoautomovel" ><i class="fa fa-chevron-circle-right" style="color:white;"></i>
                Crédito Automóvel</router-link></li>
              <li><router-link to="/creditolarrecheio" > <i class="fa fa-chevron-circle-right" style="color:white;"></i>
                Crédito Pessoal Lar Recheio</router-link></li>
              <li><router-link to="/creditohabitacao" ><i class="fa fa-chevron-circle-right" style="color:white;"></i>
                Crédito Habitação</router-link></li>
            </ul>
          </div>
        </div>
        <div class="col-lg-3 col-md-3">
          <div class="footer__brand">
            <h5>Crédito Empresa</h5>
            <ul>
              <li><i class="fa fa-chevron-circle-right" style="color:white;"></i>
              <router-link to="/creditorural" >Crédito Rural Agropecuária</router-link>
              </li>
              <li><router-link to="/creditopme" ><i class="fa fa-chevron-circle-right" style="color:white;"></i>Crédito PME-
                Rural Comércio </router-link></li>
            </ul>
          </div>
        </div>
        <div class="col-lg-3 col-md-3">
          <div class="footer__about" style="text-align:right">
            <div class="footer__logo">
              <a href="index.html"><img src="img/footer-logo.png" alt=""></a>
            </div>
            <p style="text-align:right">Questão? Consulte a sua duvida em nossa linha do cliente:<br>
              <b>(+258) 824731609/844867035</b></p>
            <div class="footer__social">
              <a href="https://www.facebook.com/acmicrobanco" class="facebook"><i class="fa fa-facebook"></i></a>
              <a href="https://twitter.com/ac_microbanco" class="twitter"><i class="fa fa-twitter"></i></a>
              <!-- <a href="#" class="google"><i class="fa fa-google"></i></a>
              <a href="#" class="skype"><i class="fa fa-skype"></i></a> -->
            </div>
          </div>
        </div>
      </div>
      
      <div class="footer__copyright__text" >
        <p style="text-align:center">AC MicroBanco é licenciado e regulado pelo Banco de Moçambique </p> 
        <p style="text-align:center">&copy;{{ year }} AC MicroBanco - Todos os direitos reservados 
        </p>
      </div>
      
    </div>
  </footer>
  <!-- Footer Section End -->
</template>

<script>
export default {
  name: "m-footer",
  data() {
    return {
      year: new Date().getFullYear()
    }
  }
}
</script>

<style>
.footer{
  background-image: url('~@/assets/img/footer-bg.jpg');
}
</style>
