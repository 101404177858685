<template>
  <!-- Header Section Begin -->
  <header class="header">
    <div class="header__top">
      <div class="container">
        <div class="row">
          <div class="col-lg-7">
            <ul class="header__top__widget">
              <li><i class="fa fa-clock-o"></i> Aberto: {{ info.working_hours }}</li>
              <li><i class="fa fa-envelope-o"></i>{{ info.email }}</li>
            </ul>
          </div>
          <div class="col-lg-5">
            <div class="header__top__right">
              <div class="header__top__phone">
                <i class="fa fa-phone"></i>
                <span>{{ info.phone }}</span>
              </div>
              <div class="header__top__social">
                <a href="https://www.facebook.com/acmicrobanco"><i class="fa fa-facebook"></i></a>
                <a href="https://twitter.com/ac_microbanco"><i class="fa fa-twitter"></i></a>
                <!-- <a href="#"><i class="fa fa-google"></i></a>
                <a href="#"><i class="fa fa-instagram"></i></a> -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>


    <div class="container1">


      <div class="col-lg-4">
        <div class="header__logo">
          <a href="./index.html"><img src="img/logo300-blue.png" alt=""></a>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-12">
          <div class="header__nav">
            <nav class="header__menu">
              <ul>
                <li>
                  <router-link :to="{name: 'Home'}" active-class="active">Home</router-link>
                </li>
                <li>
                  <router-link :to="{name: 'About'}">Sobre Nós</router-link>
                  <ul class="dropdown">
                    <li><router-link :to="{name: 'History'}">História</router-link></li>
                    <li><router-link to="/About#section2">Missão</router-link></li>
                    <li><router-link to="/About#section2">Visão</router-link></li>
<!--                    <li><a href="about.html#section2">Missão </a></li>-->
<!--                    <li><a href="about.html#section2">Visão </a></li>-->
                    <li><router-link :to="{name: 'Structure'}">Estrutura</router-link></li>
                    <li><router-link :to="{name: 'Reports'}">Relatórios</router-link></li>
                  </ul>
                </li>
                <li><a href="#">Crédito</a>
                  <ul class="dropdown">
                    <li><router-link :to="{name: 'Particulares'}">Particulares</router-link></li>
                    <li><router-link :to="{name: 'Empresas'}">Empresas</router-link></li>

                  </ul>
                </li>
                <li><router-link :to="{name: 'Contact'}">Onde Estamos</router-link></li>
              </ul>
            </nav>
            <div class="header__nav__widget">

              <router-link :to="{name: 'Simulador'}" class="primary-btn">Simular</router-link>
              <div class="header__nav__widget__btn">
                <!--                 <a href="#"><i class="fa fa-cart-plus"></i></a>-->
                <!-- <a href="#" @click.prevent="openSearch" class="search-switch ml-4"><i class="fa fa-search"></i></a> -->
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="canvas__open">
        <span class="fa fa-bars"></span>
      </div>
    </div>
  </header>
  <!-- Header Section End -->
</template>

<script>
import info from '@/data/info.json'

export default {
  name: "m-header",
  methods: {
    openSearch() {
      window.$('.search-model').fadeIn(400);
    }
  },
  data() {
    return {
      info: info
    }
  }
}
</script>

<style scoped>

</style>
